<template>
  <p-dialog :title="`${$tk('Reports.Invoice', true)} ${invoice.name}`" classes="w-4/5" @close="$emit('close')">

    <div class="-mt-1 bg-white border-b border-gray-300">
      <p-tabs class="mx-4">
        <p-tab v-model="tab" name="lines">{{ $tk('Reports.Summary', true) }}</p-tab>
        <p-tab v-model="tab" name="invoice">{{ $tk('Reports.Invoice', true) }}</p-tab>
        <template v-if="invoice.saleOrigin === 'POOLING'">
          <p-tab v-model="tab" name="stock">{{ $tk('Reports.RentalBasis', true) }}</p-tab>
          <p-tab v-model="tab" name="transactions">{{ $tk('Reports.Transactions', true) }}</p-tab>
        </template>
      </p-tabs>
    </div>

    <invoice-dialog-invoice :invoice="invoice" v-if="tab === 'invoice'"></invoice-dialog-invoice>
    <invoice-dialog-lines :type="type" :invoice="invoice" v-if="tab === 'lines'"></invoice-dialog-lines>
    <invoice-dialog-stocks :invoice="invoice" v-if="tab === 'stock'"></invoice-dialog-stocks>
    <invoice-dialog-transactions :invoice="invoice" v-if="tab === 'transactions'"></invoice-dialog-transactions>

    <p-dialog-actions>
      <div class="flex justify-between w-full">
        <div class="flex gap-2">
          <p-button icon="file-excel" color="secondary">Excel</p-button>            
          <email-invoice-button :invoiceId="this.invoice.id"></email-invoice-button>
        </div>
        <p-button @click="$emit('close')">{{ $tk('Common.Actions.Close') }}</p-button>
      </div>
    </p-dialog-actions>
  </p-dialog>
</template>

<script>

import InvoiceDialogLines from "./InvoiceDialog_Lines.vue"
import InvoiceDialogStocks from "./InvoiceDialog_Stocks.vue"
import InvoiceDialogTransactions from "./InvoiceDialog_Transactions.vue"
import InvoiceDialogInvoice from "./InvoiceDialog_Invoice.vue"
import EmailInvoiceButton from "../components/EmailInvoiceButton.vue"

export default {
  name: "InvoiceDialog",
  components: {
    InvoiceDialogLines,
    InvoiceDialogStocks,
    InvoiceDialogTransactions,
    InvoiceDialogInvoice,
    EmailInvoiceButton
  },

  props: {
    type: {
      type: String,
      required: true
    },
    invoice: {
      type: Object,
      required: true
    }
  },

  methods: {
    downloadInvoice() {
    },
  },

  data() {
    return {
      tab: "lines",

    }
  },

}
</script>
